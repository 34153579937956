<template>
  <Spinner v-if="isLoading"/>

  <div v-else class="product-edit">
    <div class="between ai-c mb-30">
      <VButtonPageBack :callback="onPageBack" />
      <!-- <LinkPageBack
        class="color--main hover--main"
        route-name="Catalog"
        :route-params="routeParams"
      /> -->

      <div v-if="productId" class="d-flex gap-s">
        <VButton
          v-if="permissionDestroyProduct"
          text="Удалить"
          icon="remove"
          size="sm"
          @click.native="openModal('confirmationRemove')"
        />

        <VButton
          v-if="permissionUpdateProduct"
          text="Редактировать"
          icon="edit"
          size="sm"
          @click.native="TOGGLE_IS_EDIT"
        />
      </div>
    </div>

    <div class="between ai-c mb-30">
        <h1>{{ pageTitle }}</h1>

        <InfoText
            label="Дата создания"
            :text="createdAt"
        />
    </div>

    <VForm
      v-model="productFormData"
      class="gap-s"
      scroll-when-invalid
      @request="saveProductHandler"
    >
      <ProductHeadFields 
        :typePosition="productFormData.spring_type"
      />

      <ProductMainFields />

      <ProductGeneralFields />

      <ProductCoatingFields />

      <ProductPositionFields
        v-if="permissionReadProductPositionFields"
        v-model="productFormData.position"
        ref="ProductPositionFields"
        name="position"
        :type-position="productFormData.spring_type"
        :shape-catalog="productFormData.shape"
      />

      <ButtonGroup
        v-if="$store.state.catalog.product.isEdit"
        textBtnConfirm="Сохранить"
        textBtnReject="Отмена"
        btn-size="l"
        :loading="$store.state.isSendingRequestPage"
        @reject="goToCatalogById"
      />
    </VForm>

    <ModalConfirmation
      v-if="isModal === 'confirmationRemove'"
      @confirm="destroyProductHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
// import LinkPageBack from '@/components/ui/link/LinkPageBack'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation'
import ProductHeadFields from '@/components/views/Catalog/Product/ProductHeadFields'
import ProductMainFields from '@/components/views/Catalog/Product/ProductMainFields'
import ProductGeneralFields from '@/components/views/Catalog/Product/ProductGeneralFields'
import ProductCoatingFields from '@/components/views/Catalog/Product/ProductCoatingFields'
import ProductPositionFields from '@/components/views/Catalog/Product/ProductPositionFields'
import VButton from '@/components/simple/button/VButton'
import VForm from '@/components/Form/VForm'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack'
import InfoText from '@/components/simple/text/InfoText'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ProductPosition from '@/classes/model/Catalog/ProductPosition'

import { goToPage } from '@/utils/router'
import { scrollUp } from '@/utils/dom'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import Product from '@/classes/model/Catalog/Product'
import { prepareFormDataPosition } from '@/utils/production/position'
import { findById, findByKeyValue } from '@/utils/array/find'
import { bySort } from '@/utils/array/sort'
import { getIds } from '@/utils/array'
import { deepClone } from '@/utils/deepClone'
import { prepareFormDataCommaToDot } from '@/utils/form/prepareFormDataCommaToDot'
import { formatDate } from '@/utils/format/date'

export default {
  name: 'ProductEdit',
  components: {
    ButtonGroup,
    // LinkPageBack,
    ModalConfirmation,
    ProductCoatingFields,
    ProductGeneralFields,
    ProductHeadFields,
    ProductMainFields,
    // eslint-disable-next-line vue/no-unused-components
    ProductPositionFields,
    VButton,
    VForm,
    VButtonPageBack,
    InfoText
  },
  mixins: [mixinModal, mixinIsLoading],

  data: () => ({
    productFormData: {
      // head
      spring_type: null,
      shape: null,
      article: '',
      categories: [],
      additional_articles: [],

      // main
      files_images: [],
      in_stock: 0,
      name: '',
      note_product: '',
      d_wire: '',
      length: '',
      weight: '',
      diameter: '',
      width: '',
      material: '',
      tech_brand: null,
      n_n1: '',

      // general
      application_areas: [],
      tech_type: null,
      product_type: null,
      country: null,
      files_start: [],
      files_sales: [],
      files_admin: [],
      applicability: '',
      note: '',

      // price
      note_admin: '',
      coating: null,
      color: null,
      prices: [],

      position: {}
    },
    filesSortData: [],

    isWatchSpringType: false
  }),

  computed: {
    ...mapState('catalog/product', {
      currentProduct: 'currentProduct'
    }),
    ...mapGetters('permission', {
      permissionUpdateProduct: 'permissionUpdateProduct',
      permissionDestroyProduct: 'permissionDestroyProduct',
      permissionReadProductPositionFields: 'permissionReadProductPositionFields'
    }),

    categoryId () {
      return +this.$route.params.categoryId || null
    },
    productId () {
      return +this.$route.params.productId
    },
    formPosition () {
      return this.$refs.ProductPositionFields.$refs.PositionFormDetails.$refs.positionFormRef
    },

    routeParams () {
      return {
        categoryId: this.categoryId
      }
    },
    pageTitle () {
      return this.productId ? 'Редактировать карточку' : 'Создать карточку'
    },
    createdAt () {
        return this.productFormData.created_at ? formatDate(this.productFormData.created_at) : ''
    }
  },

  watch: {
    productId: {
      async handler (id) {
        this.RESET_FILES_TO_DELETE()
        if (!id) return

        this.stopIsWatchSpringType()
        await this.loadProductById()
        this.startIsWatchSpringType()
      },
      immediate: false
    },

    'productFormData.spring_type' (val) {
      if (val.id !== 1 && val.id !== 2) this.productFormData.shape = null
      if (!this.isWatchSpringType) return

      this.clearProductPositionFields()
    }
  },

  created () {
    this.getAllArticles()
    this.loadCatalogHandbook()
    if (this.productFormData.spring_type) return
    this.productFormData.spring_type = this.$store.getters['records/defaultPositionType']
  },

  async mounted () {
    scrollUp()
    await this.initFetch()
    await this.$store.dispatch('menu/setSidebarMenu')

    if (this.productId) {
      await this.loadProductById()
    } else {
      this.TURN_ON_IS_EDIT()
    }

    this.startIsWatchSpringType()
  },

  destroyed () {
    this.TURN_OFF_IS_EDIT()
    this.RESET_CURRENT()
    this.RESET_FILES_TO_DELETE()
  },

  methods: {
    ...mapMutations('catalog/product', {
      TURN_ON_IS_EDIT: 'TURN_ON_IS_EDIT',
      TURN_OFF_IS_EDIT: 'TURN_OFF_IS_EDIT',
      TOGGLE_IS_EDIT: 'TOGGLE_IS_EDIT',
      RESET_CURRENT: 'RESET_CURRENT',
      RESET_FILES_TO_DELETE: 'RESET_FILES_TO_DELETE'
    }),

    ...mapActions('handbook', {
      loadCatalogHandbook: 'loadCatalogHandbook'
    }),
    ...mapActions('records', {
      getAllArticles: 'getAllArticles'
    }),

    ...mapActions('catalog/categories', {
      getAllCategories: 'getAllCategories'
    }),
    ...mapActions('catalog/product', {
      getProductById: 'getProductById',
      createProduct: 'createProduct',
      updateProduct: 'updateProduct',
      destroyProduct: 'destroyProduct',
      deleteFiles: 'deleteFiles',
      updateFilesSort: 'updateFilesSort'
    }),

    ...mapMutations('scroll', {
      setScrollNodeId: 'SET_NODE_ID'
    }),

    onPageBack () {
      this.setScrollNodeId(`product-card--${this.productId}`)
      goToPage('Catalog', this.routeParams)
    },

    async initFetch () {
      try {
        this.startLoading()
        await Promise.all([
          this.getAllCategories()
        ])
      } finally {
        this.finishLoading()
      }
    },

    async loadProductById () {
      try {
        this.startLoading()

        await this.getProductById(this.productId)

        this.syncProductFormData()
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    async saveProductHandler (data) {
      let invalid = false

      if (this.formPosition.hasValidationErrors()) {
        invalid = true
      }

      if (invalid) return

      try {
        this.$store.commit('START_IS_SENDING_REQUEST_PAGE')

        const prepared = this.prepareProductRequest(data)

        if (this.productId) {
          this.rememberFilesSort()

          this.filterProductRequestFiles(prepared)
          await this.updateProductAndFiles(prepared)
          this.syncProductFormData()

          await this.sendFilesSort()
        } else {
          await this.createProduct(prepared)
          this.goToProductEditBy(this.currentProduct?.id)
        }

        this.TURN_OFF_IS_EDIT()
        showAlertSuccess('Карточка сохранена')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.resetFilesSortData()
        this.$store.commit('FINISH_IS_SENDING_REQUEST_PAGE')
      }
    },

    prepareProductRequest (data) {
      let prepared = prepareFormDataIdArray(data, Product.keyList.toIds)
      prepared = prepareFormDataId(prepared, Product.keyList.toId)
      prepared = prepareFormDataCommaToDot(prepared, Product.keyList.commaToDot)
      Product.addPrefixProductAndDeleteOldKeyForRequest(prepared)

      prepared = { ...prepared, ...prepareFormDataPosition(prepared.position) }
      delete prepared.position

      // Пустой массив не попадает в formData и при удалении списка цен, они не удаляются на бэке.
      if (!prepared.product_prices.length) {
        prepared.product_prices = ''
      } else {
        Product.formatPrices(prepared.product_prices)
      }

      return prepared
    },

    async sendFilesSort () {
      if (!this.productFormData.files_images.length) return

      this.setFilesSort()
      this.sortFiles()
      const ids = getIds(this.productFormData.files_images)

      await this.updateFilesSort(ids)
    },

    sortFiles () {
      this.productFormData.files_images.sort(bySort)
    },

    setFilesSort () {
      this.filesSortData.forEach(item => {
        let file

        if (item.id) {
          file = findById(this.productFormData.files_images, item.id)
        } else {
          const fileByName = findByKeyValue(this.productFormData.files_images, 'name', item.name)
          if (!fileByName) return

          if (fileByName.size === item.size) {
            file = fileByName
          }
        }

        if (!file) return
        file.sort = item.sort
      })
    },

    rememberFilesSort () {
      this.productFormData.files_images.forEach((file, i) => {
        this.filesSortData.push({
          name: file.name,
          size: file.size,
          id: file.id || null,
          sort: i
        })
      })
    },

    resetFilesSortData () {
      this.filesSortData.length = 0
    },

    filterProductRequestFiles (data) {
      for (const key of Product.keyList.files) {
        if (!data[key]?.length) continue

        data[key] = data[key].filter(file => !file?.id)
      }
    },

    async updateProductAndFiles (data) {
      await this.deleteFiles()
      await this.updateProduct({ data, id: this.productId })
    },

    async destroyProductHandler () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        await this.destroyProduct(this.productId)
        this.closeModal()

        showAlertSuccess('Карточка удалена')
        await goToPage('Catalog', { categoryId: this.categoryId })
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    syncProductFormData () {
      this.stopIsWatchSpringType()

      const product = new Product(deepClone(this.currentProduct))

      for (const key in product) {
        Vue.set(this.productFormData, key, product[key])
      }

      this.$nextTick(this.startIsWatchSpringType)
    },

    startIsWatchSpringType () {
      this.isWatchSpringType = true
    },
    stopIsWatchSpringType () {
      this.isWatchSpringType = false
    },

    clearProductPositionFields () {
      this.productFormData.position = new ProductPosition({})
    },

    goToCatalogById () {
      return goToPage('Catalog', this.routeParams)
    },

    goToProductEditBy (productId) {
      goToPage('ProductEdit', { ...this.routeParams, productId })
    }
  }
}
</script>
