<template>
  <div
    class="product-coating-fields box"
    :class="!hasPriceList && 'product-coating-fields--not-content'"
  >
    <VButton
      v-if="hasPriceList && canEdit"
      text="Настроить категории"
      class="product-coating-fields__button"
      size="sm"
      icon="settings"
      @click.native="openModal('cat')"
    />

    <VButton
      v-if="!hasPriceList && canEdit"
      text="Создать категории цен"
      class="product-coating-fields__button"
      icon="create"
      size="sm"
      color="primary-sm"
      @click.native="openModal('cat')"
    />

    <ProductCoatingPriceFields
      v-if="hasPriceList"
      name="prices"
      class="mb-30"
    />

    <h3 v-if="hasPriceList" class="mb-30">Покрытие</h3>

    <div v-if="hasPriceList" class="form-grid--col-2 mb-20">
      <VSelect
        label="Тип покрытия"
        name="coating"
        placeholder="Не выбрано"
        :options="coatingOptionsFiltered"
        searchable
        :disabled="!canEdit"
      />

      <VSelect
        label="Цвет покрытия"
        name="color"
        :options="colorOptionsFiltered"
        searchable
        :disabled="!canEdit"
      />
    </div>

    <VButton
      v-if="hasPriceList && !canEdit"
      class="mb-20"
      text="Сформировать КП"
      size="xl"
      @click.native="openModal('copy')"
    />

    <VInput
      v-if="hasPriceList && (isAdmin || isDirector)"
      label="Примечание для администратора"
      class="max-w-form-grid--col-2"
      name="note_admin"
      type="textarea"
      :disabled="!canEdit"
    />

    <NoContentBox
      v-if="!hasPriceList"
      text="Категории цен не созданы"
    />

    <ModalProductPriceCategory
      v-if="isModal === 'cat'"
      :value="formData.prices"
      @request="saveProductPriceCategoryHandler"
      @reject="closeModal"
    />

    <ModalCopy
      v-if="isModal === 'copy'"
      :data="formData"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ModalProductPriceCategory from '@/components/views/Catalog/Modal/ModalProductPriceCategory'
import NoContentBox from '@/components/production/NoContentBox'
import ProductCoatingPriceFields from '@/components/views/Catalog/Product/ProductCoatingPriceFields'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'
import VSelect from '@/components/Form/VSelect'
import ModalCopy from '@/components/ui/Modal/ModalCopy'

import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinCatalogProductIsEdit } from '@/mixins/views/Catalog/Product/mixinCatalogProductIsEdit'
import { mixinCoatingAndColorOptionsFiltered } from '@/mixins/views/mixinCoatingAndColorOptionsFiltered'

import { mapGetters } from 'vuex'
import { refreshArr } from '@/utils/array/reactive'

export default {
  name: 'ProductCoatingFields',
  components: {
    ModalProductPriceCategory,
    NoContentBox,
    ProductCoatingPriceFields,
    VButton,
    VInput,
    VSelect,
    ModalCopy
  },
  mixins: [
    mixinModal,
    mixinCatalogProductIsEdit,
    mixinCoatingAndColorOptionsFiltered
  ],

  inject: ['formData'],
  computed: {
    ...mapGetters('permission', {
      permissionCreateProduct: 'permissionCreateProduct',
      permissionUpdateProduct: 'permissionUpdateProduct',
      isAdmin: 'isAdmin',
      isDirector: 'isDirector'
    }),

    hasPriceList () {
      return this.formData.prices?.length
    },

    // Используется в mixinCoatingAndColorOptionsFiltered
    currentCoating () { return this.formData.coating },
    currentColor () { return this.formData.color }
  },
  methods: {
    saveProductPriceCategoryHandler (newPrice) {
      refreshArr(this.formData.prices, newPrice)
      this.closeModal()
    }
  }
}
</script>
